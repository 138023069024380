@media (max-width: 575.98px) { 
  #home {
    /*height: 200vh;*/
  }
}
@media (min-width: 575.98px) { 
  #home {
    /*height: 100vh;*/
    min-height: -webkit-fill-available;
  }
}
#home {
    /*background-image: linear-gradient(150deg, #fdfbfb 0%, #eee 100%);*/
    /*background-image: linear-gradient(to right bottom, #ffffff, #fbfbfd, #f6f7fa, #f1f4f8, #ecf0f6);*/
    background: linear-gradient(150deg, rgba(255,255,255,1) 0%, rgba(246,247,250,1) 75%, rgba(236,240,246,.5) 100%);
    /*padding-top: 20px;*/
  }

strong.xtra {
  font-weight: 700;
}

h1.hero-title {
  line-height: 1.2;
}

.hero-sub {
  line-height: 1.7;
}

body {
  color: rgba(0, 0, 0, 0.5);
}

.section {
  padding-top: 50px;
}
.section-header {
  margin-bottom: 30px;
}
.text-medium {
  font-size: 1.2rem;
}
.extra-weight {
  font-weight: 700;
}
